import React, { Component } from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import logo from './3rlogo.png'

export default class Environment extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isLoggedIn: null
        }
        this.logOut = this.logOut.bind(this);
    }

    async componentDidMount() {
        // console.log("*** component mount header *****")
          
    }

    logOut(){
	  	localStorage.clear()
	  	const { history } = this.props 
	    let redirectRoute = "/"
	    console.log("history :",history)
	    // history.push(redirectRoute)	
	}

    render() {
        return <AppBar position="relative" className="Bar">
	            <Toolbar>
	              <div>
	              	<img src={logo} alt="logo" className="Logo"/>
	              </div>
	              <Typography variant="h6" color="inherit" style={{ flex: 0.9 }} noWrap>
	                Voco Engagement Portal Login
	              </Typography>
	              <div>
	              	{
	                    this.state.isLoggedIn ? <ExitToAppIcon onClick={() => this.logOut()}/>  : null 
	                }                          
	              </div>                      
	            </Toolbar>
	        </AppBar>
    }
}
