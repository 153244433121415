import React from 'react'
import Voco from './routes/Voco'
import Environment from './routes/Environment'
import Wait from './routes/Wait'
import WaitThenHMAC from './routes/WaitThenHMAC'
import Schemes from './routes/Schemes'
import './App.css'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import config from './config'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import HeaderBar from './header.js'
import FooterBar from './footer.js'

import {
    Link,
    HashRouter as Router,
    Switch,
    Route
} from "react-router-dom"

function App() {
    return (
        <Router>
            <div className="App">
                <HeaderBar/>                 
                <Switch>
                    <Route path = "/Environment/:environmentId" component={Environment}/>
                    <Route path = "/Voco/:environmentId" component={Voco}/>
                    <Route path = "/Wait/:environmentId" component={Wait}/>
                    <Route path = "/WaitThenHMAC/:environmentId" component={WaitThenHMAC} />
                    <Route path = "/Schemes/:environmentId" component={Schemes}/>
                    <Route path = "/">
                        <Grid
                          container
                          spacing={4}
                          direction="column"
                          alignItems="center"
                          justify="center"
                          style={{ minHeight: '70vh' }}
                        >
                          <Grid item xs={3}>
                            <Button variant="contained" size="large" className="Button" component={Link} to="/Environment/demo-ghi"> 
                                Environment
                            </Button>
                          </Grid>  
                          <Grid item xs={3}>
                            <Button variant="contained" size="large" component={Link} to="/Voco/demo-ghi">
                                Voco
                            </Button>
                          </Grid>
                        </Grid>                         
                    </Route>
                </Switch>
                
            </div>
            <div className="Footer">
                <FooterBar/>  
            </div>
        </Router>
    )
}

export default App;
