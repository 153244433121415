import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Axios from 'axios'
import '../App.css'
import config from '../config'
import { CircularProgress, Button } from '@material-ui/core'
import logo from "../3r_logo.png"


export default class Voco extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            username: '',
            password: '',
            title : ''
        }
        this.login = this.login.bind(this);
    }

    componentDidMount() {
        let params = this.props.match.params
        let envObject = (config.environments || {})[params.environmentId]

        if (envObject) {
            this.setState({ title : envObject.title })
            document.title = "Voco: " + envObject.title
        }
    }

    login(evt){
    	evt.preventDefault();
    	this.setState({ loading: true });
    	let params = this.props.match.params
    	let configEnvironment = config.environments
    	if(!configEnvironment){
    		this.setState({
                error: true,
                success: false,
                loading:false,
                errorMessage: "No config environments!"
            })
    	}
        let envIdObject = configEnvironment[params.environmentId]
        
        if(envIdObject){
        	let loginUrl = "https://"+ params.environmentId +"-voco.vocohub.com/_/gtp//web-service/0/marketers-app-login"
        	var that = this
			Axios({
			    method: 'post',
			    url: loginUrl,
			    data: {username: this.state.username, password : this.state.password, action: 'login'},
			    headers: {'content-type': 'application/json'}
		    }).then(function (response) {
		        if(response.data && !response.data.ok){
		        	that.setState({
		                error: true,
		                success: false,
		                loading:false,
		                errorMessage: response.data.status
		            })
		        }else {
		        	const { history } = that.props
		        	let redirectRoute = "/Wait/" + params.environmentId
		        	let userData = {
		        		email : response.data.email,
		        		groupId : response.data.groupId,
		        		token : response.data.token
		        	}
		        	localStorage.setItem(params.environmentId + '-user', JSON.stringify(userData))
		        	
		        	that.setState({
		                success: true,
		                error: false,
		                loading:false,
		                isLoggedIn : true,
		                successMessage: "User authentication success. Redirecting to Environment..."
		            })
		        	setTimeout(() => history.push(redirectRoute), 100)
		        } 
		    })
		    .catch(function (response) {
		        console.log("Error :",response)
		        that.setState({
	                error: true,
	                loading:false,
	                success: false
	                // errorMessage: response
	            })
		    })
        }else {
        	this.setState({
                error: true,
                success: false,
                loading:false,
                errorMessage: "EnvironmentId - '" + params.environmentId +"'' not found in config!"
            })
        }
    }

    render() {
        return (
		    <Container component="main" maxWidth="xs">
		      <CssBaseline />
		      <div className="paper">
		        <Avatar src={logo} className="avatar">
		        </Avatar>
		        <Typography component="h1" variant="h5">
		          Log in: {this.state.title}
		        </Typography>
		        <form className="form" onSubmit={(e)=>{this.login(e)}} noValidate>
		          <TextField
		            variant="outlined"
		            margin="normal"
		            required
		            fullWidth
		            id="username"
		            label="Username"
		            name="username"
		            autoComplete="username"
		            autoFocus
		            onChange = {(e) => this.setState({username:e.target.value})}
		          />
		          <TextField
		            variant="outlined"
		            margin="normal"
		            required
		            fullWidth
		            name="password"
		            label="Password"
		            type="password"
		            id="password"
		            autoComplete="current-password"
		            onChange = {(e) => this.setState({password:e.target.value})}
		          />
		         <ButtonComponent
		         	 loading={this.state.loading} 
		         />
		      
		        </form>
		      </div>
		      <div>
		      		{this.state.success && < h3 className = "success" > { this.state.successMessage } < /h3> }
		      		{this.state.error && < h3 className = "error" > { this.state.errorMessage } < /h3> } 
		      </div>
		    </Container>
		  );
	    }
}

function ButtonComponent(props) {
  const { onClick, loading } = props;
  return (
    <Button variant="contained" type="submit" fullWidth color="primary" className="submit" disabled={loading}>
      {loading && <CircularProgress size={20} />}
      {!loading && 'Log In'}
    </Button>
  );
}
