import configLocal from "./config.local.json"
import configDemo from "./config.demo.json"
import configPro from "./config.pro.json"

let config = null
let environment = process.env['NODE_ENV'] || "development" 

if (window.location.hostname === "localhost") {
    config = configLocal
} else if (environment === "development" || window.location.hostname.startsWith("demo-")) {
    config = configDemo
} else {
    config = configPro
}

export default config

