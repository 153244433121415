import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import logo from './3rlogo.png'
import Link from '@material-ui/core/Link';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        3Radical
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'} All rights reserved
    </Typography>
  );
}


export default class Environment extends Component {
    constructor(props) {
        super(props);
      
    }

    render() {
        return <footer className="Footer">
		        <Copyright />
		      </footer>
    }
}
