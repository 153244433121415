import React, { Component } from 'react'
// import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
// import ImageIcon from '@material-ui/icons/Image';
// import WorkIcon from '@material-ui/icons/Work';
import FolderIcon from '@material-ui/icons/Folder';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import config from '../config'
import Axios from 'axios'
import Link from '@material-ui/core/Link'
import CircularProgress from '@material-ui/core/CircularProgress'

export default class Schemes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            schemeList : null,
            loading:false
        }
        this.getSchemes = this.getSchemes.bind(this);
        this.redirectUrl = this.redirectUrl.bind(this);
    }

    componentDidMount(){
	    this.getSchemes();
	}

	getSchemes(){
		this.setState({ loading: true });
		let params = this.props.match.params
    	let configEnvironment = config.environments
        let envIdObject = configEnvironment[params.environmentId]

        if(envIdObject){
        	let url = "https://"+ params.environmentId +"-voco.vocohub.com/_/gtp//web-service/0/marketers-app-login"
        	let userSessionData = JSON.parse(localStorage.getItem(params.environmentId + '-user'))
    
        	if(!userSessionData){
        		 this.setState({
	                error: true,
	                loading:false,
	                success: false,
	                errorMessage: "No valid user authentication!"
	            })
        		const { history } = this.props 
		        let redirectRoute = "/"
		        setTimeout(() => history.push(redirectRoute), 5000)	
        	}else {
        		Axios({
				    method: 'post',
				    url: url,
				    data: {
				    		email: userSessionData.email, 
				    		token : userSessionData.token, 
				    		groupId: userSessionData.groupId, 
				    		action: 'schemes'
				    },
				    headers: {'content-type': 'application/json'}
			    }).then((schemeResp) => {
			        if(schemeResp.data && !schemeResp.data.length){
			        	this.setState({
			                error: true,
			                success: false,
			                loading:false,
			                errorMessage: "No schemes for the environment!"
			            });

			        }else {			        	
			        	setTimeout(() => 
				        	this.setState({
				                success: true,
				                error: false,
				                loading:false,
				                successMessage: "Schemes for the environment",
				                schemeList : schemeResp.data
			            }), 2000)
			        } 
			    })
			    .catch((err) => {
			        console.log("Error :",err)
			        this.setState({
		                error: true,
		                loading:false,
		                success: false,
		                errorMessage: err
		            })
			    })
        	};
        } else {
        	this.setState({
                error: true,
                success: false,
                loading:false,
                errorMessage: "EnvironmentId - '" + params.environmentId +"'' not found in config!"
            })
        }
	}

	redirectUrl(schemeId){
		let params = this.props.match.params
		let url = "https://"+ params.environmentId +"-voco.vocohub.com/_/gtp//web-service/0/marketers-app-login"
		let user = JSON.parse(localStorage.getItem(params.environmentId + '-user'))
 
		Axios({
		    method: 'post',
		    url: url,
		    data: {
		    		schemeId: schemeId,
		    		token: user.token, 
		    		environmentId : params.environmentId, 
		    		email: user.email,
		    		action: 'redirect'
		    },
		    headers: {'content-type': 'application/json'}
	    }).then((redirectRespUrl) => {
	        console.log('URL :', redirectRespUrl)
	        if(redirectRespUrl.status == 200){
	        	 localStorage.clear()
	        	 window.location.href=redirectRespUrl.data.marketerLaunchUrl
	        	 // window.open(redirectRespUrl.data.marketerLaunchUrl, '_blank') 
	        }
            
	    })
	    .catch((err) => {
	        console.log("Error :",err)
	        this.setState({
                error: true,
                loading:false,
                success: false
            })
	    })
	}

	renderSchemesList(){
		if(this.state.schemeList){
			return this.state.schemeList.map(scheme => {
		        return <ListItem component={Link} className="scheme-select-cursor" key={scheme.id} onClick={() => this.redirectUrl(scheme.id)} >
				            <ListItemAvatar>
					              <Avatar>
					                <FolderIcon />
					              </Avatar>
				            </ListItemAvatar>
				            <ListItemText primary={scheme.name} secondary={scheme.id} />
			          </ListItem>
		    })
		}
	}

    render() {
    	return (
    		<div >
	            <h2> Choose Scheme < /h2> 
	    		<List className="Schemes">
	    			{this.state.loading && <CircularProgress size={20} />}
	                {this.renderSchemesList()} 
	                {this.state.error && < h3 className = "error" > { this.state.errorMessage } < /h3> } 
	            </List>
	        </div>

    	)

    	
    }
}
