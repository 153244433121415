import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import config from '../config'

export default class Environment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false
        }
        this.checkEnvironmentId = this.checkEnvironmentId.bind(this);
    }

    checkEnvironmentId() {
        let params = this.props.match.params
        let configEnvironment = config.environments
        let envIdObject = configEnvironment[params.environmentId]
        const { history } = this.props 
        if (envIdObject) {
            switch(envIdObject.type) {
	          case "Voco":
	          		this.setState({
		                success: true,
		                successMessage: "EnvironmentId - '" + params.environmentId +"' exists in config. Redirecting to Voco login..."
		            })
	          	   let redirectRoute = "/"+ envIdObject.type + "/" + params.environmentId
	          	   setTimeout(() => history.push(redirectRoute), 5000)
	            break;
	          default:
	              this.setState({
		                error: true,
		                errorMessage: "Environment type - '" + envIdObject.type +"' not supported at the moment!"
		            })
		    }
        } else {
            this.setState({
                error: true,
                errorMessage: "EnvironmentId - '" + params.environmentId +"'' not found in config!"
            })
        }
    }

    componentDidMount(){
	    this.checkEnvironmentId();
	 }

    render() {
        const { params } = this.props.match
        return ( 
        	<div >
	            <h1> Environment < /h1> 
	            <p> Environment Id Parameter:  { params.environmentId } < /p> 
                
	            {this.state.success && <Typography component="h1" variant="h5" > { this.state.successMessage }</Typography> }
	            {this.state.error && < h3 className = "error" > { this.state.errorMessage } < /h3> } 
            </div>
            )
        }
    }